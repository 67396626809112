import Vue from 'vue'
import Router from 'vue-router'
import VueI18n from "@/i18n.js";
// const content = () => import( /* webpackChunkName: "Content" */'./views/Content.vue')
Vue.use(Router)

export default new Router({
    mode: 'history',
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: "/:lang",
            component: {
                render: h => h("router-view")
            },
            beforeEnter(to, from, next) {
                const lang = to.params.lang;
                if (!["en", "de"].includes(lang)) return next("de");
                if (VueI18n.locale !== lang) {
                    VueI18n.locale = lang;
                }
                return next();
            },
            children: [
                {
                    path: '',
                    name: 'home',
                    component: () => import('./views/Home.vue')
                },
                {
                    path: 'info',
                    name: 'info',
                    component: () => import('./views/Info.vue')
                },
                {
                    path: 'urushi',
                    name: 'urushi',
                    component: () => import('./views/Urushi.vue')
                },
                {
                    path: 'galerie',
                    name: 'galerie',
                    component: () => import('./views/Galerie.vue')
                },
                {
                    path: 'aktuell',
                    name: 'aktuell',
                    component: () => import('./views/Aktuell.vue')
                },
            ],
        },
        {
            path: "*",
            redirect: "/de"
        }
    ]
})
