<template>
<div id="app" v-if="isLoaded">
  <navigation></navigation>
  <transition name="fade">
    <router-view :key="$route.fullPath"></router-view>
  </transition>
</div>
</template>

<script>
const Navigation = () => import( /* webpackChunkName: "Navigation" */ '@/components/Navigation.vue')

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      isLoaded: false,
      transitionName: 'fade',
    }
  },
  beforeCreate() {
    this.$store.dispatch('loadContent', this.$i18n.locale).then(() => {
      this.isLoaded = true
    })
  },
  computed: {
    site() {
      return this.$store.state.site
    }
  }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'Circular';
    src: url("/public/fonts/CircularPro-Medium.otf");
}
@font-face {
    font-family: 'CircularItalic';
    src: url("/public/fonts/CircularStd-MediumItalic.otf");
}
@import 'node_modules/reset-css/sass/_reset';

* {
    box-sizing: border-box;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
    transform: translate3d(0,0,0);
}

a,
a:active,
a:hover,
a:visited {
    color: inherit;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

body,
html {
    overflow-x: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    --fs: 1.3vw;
    --lh: calc(1.25 * 1.3vw);
    @media screen and (max-width: 1024px) {
        --fs: 1.8vw;
        --lh: calc(1.4 * 1.8vw);
    }
    @media screen and (max-width: 768px) {
        --fs: 3.5vw;
        --lh: calc(1.3 * 3.5vw);
    }
    font-size: var(--fs);
    line-height: var(--lh);
}

#app {
    font-family: 'Circular', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
    overflow-x: hidden;
    em,
    i {
        font-family: 'CircularItalic', sans-serif;
    }
    .small {
        font-size: calc(0.7 * var(--fs));
        line-height: 1.2;
    }
    header {
        background: #EEE;
        display: flex;
        justify-content: space-between;
    }
    main {
        position: absolute;
        top: 0;
        left: 0;
    }
    li,
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
</style>
