import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import auth from './auth.js'


Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  state: {
    site: [],
    pages: []
  },
  getters: {
    getPageByUID: (state) => (uid, collection = state.pages) => {
      let i = collection.length
      while (i--) {
        if (collection[i].uid === uid) {
          return collection[i]
        }
      }
    }
  },
  actions: {
    async loadContent ({ commit }, locale) {
        await axios.post('/api/query', {
            "query": "site",
            "select": {
                "title": true,
                "url": true,
                "pages": {
                    "query": "site.children.listed",
                    "select": {
                        "uid": true,
                        "title": `page.content('${locale}').title`,
                        "files": {
                            "query": "page.files",
                            "select": {
                                "url": true,
                            }
                        },
                        "content": {
                            "query": `page.content('${locale}').sections.toStructure`,
                            "select": {
                                "left": true,
                                "right": true,
                                "left_color": true,
                                "left_textcolor": true,
                                "left_title": true,
                                "left_image": {
                                    "query": "structureItem.left_image.toFile",
                                    "select": {
                                        "url": "file.resize(1000).url",
                                        "thumb": "file.thumb(['width' => 10, 'quality' => 80, 'blur' => 5]).url",
                                        "extension": true,
                                    }
                                },
                                "left_mirror": true,
                                "left_video": {
                                    "query": "structureItem.left_video.toFile",
                                    "select": {
                                        "url": true,
                                    }
                                },
                                "left_text": "structureItem.left_text.kt",
                                "left_news": "structureItem.left_news.kt",
                                "right_color": true,
                                "right_textcolor": true,
                                "right_title": true,
                                "right_image": {
                                    "query": "structureItem.right_image.toFile",
                                    "select": {
                                        "url": "file.resize(1000).url",
                                        "thumb": "file.thumb(['width' => 10, 'quality' => 80, 'blur' => 5]).url",
                                        "extension": true,
                                    }
                                },
                                "right_mirror": true,
                                "right_video": {
                                    "query": "structureItem.right_video.toFile",
                                    "select": {
                                        "url": true,
                                    }
                                },
                                "right_text": "structureItem.right_text.kt",
                                "right_news": "structureItem.right_news.kt",
                            }
                        }
                    }
                }
            }
            
            // query: "site",
            // select: {
            //     title: true,
            // },
        }).then(function (response) {
            // console.log(response);
            commit('SET_SITE', response.data.result);
            commit('SET_PAGES', response.data.result.pages);
        })
        // }, {
            
        // })
    //   await axios.get('/data', {
    //     // withCredentials: true,
    //     // auth: {
    //     //   username: auth.u,
    //     //   password: auth.p
    //     // }
    //   })
    //   .then(function (response) {
    //     console.log(response);
    //     commit('SET_SITE', response.data.site)
    //     commit('SET_PAGES', response.data.pages)
    //   })
    }
  },
  mutations: {
    SET_SITE (state, site) {
      state.site = site
    },
    SET_PAGES (state, pages) {
      state.pages = pages
    }
  }
})
